import { EnvelopeFill } from "react-bootstrap-icons"
import { CircleFill } from "react-bootstrap-icons"
import {email, subject} from "../urls"

const EmailButton = () => {
    
    return(
        <div className="button_list">      
            <div>
                <a href={`mailto:${email}?subject=${encodeURIComponent(subject) || ''}`} >
                    <CircleFill className="cr_icon" />
                    <EnvelopeFill className="bs_icon" />
                </a>
            </div>
        </div>
    )
}
export default EmailButton