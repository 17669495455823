import { url } from "../urls"
import { Image } from "react-bootstrap"
const MachineShop =() => {
    return (
        <>
        <h2>Machine Shop</h2>
        <Image className="img_right" src={`${url}/static/media/FA57F0AA-FE68-4429-80D5-FA98A95CA6D7.jpeg`} />
        <p>
        DMB Performance Parts has an in-house machine shop with capabilities for 
        block boring/honing, balancing, square decking using state of the art 
        equipment and tooling.
        </p>
        </>
    )
}
export default MachineShop