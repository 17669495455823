import { tel } from "../urls";
import { isMobileDevice } from "../urls";

const PhoneText = () =>{
    const phone = `tel:${tel}`
    console.log(phone)
    return(
        <>

        
        {isMobileDevice ? 
            <a href={phone}> {tel} </a>
         : 
            <>{tel} </>
        }
       
       
        </>
    )
}
export default PhoneText