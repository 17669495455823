import { Container, Row, Col, Image } from "react-bootstrap"
import { url } from "../urls"
const Tools =() => {
    return (
        <>
        <h2>Tools</h2>
        <p>
        DMB Performance Parts is also a dealer for All Lift Cranes.
        </p>
        <Container fluid style={{textAlign: "center"}}>
            <Row >
                <Col sm={6}>
                <Image className="img_c" src={`${url}/static/media/E25EBE05-7F1D-4AB6-AE78-911B1CDA60C9.jpeg`} />
                </Col>
                <Col sm={6}>
                <Image className="img_c" src={`${url}/static/media/2C03C8A4-04BE-4FA8-BAEE-87F886F38FE2.jpeg`} />
                </Col>
            </Row>
        </Container>
   
        </>
    )
}
export default Tools