import { photos } from "../urls"
import { url } from "../urls"
import { Image, Modal } from "react-bootstrap"
import {Container, Row, Col} from "react-bootstrap"
import { useState } from "react"
const PhotoBanner=()=>{
    const [show, setShow]=useState(false)
    const [link, setLink]=useState(null)
    const handleClose = () => setShow(false); 

    const handleShow = (lk) => {
        setShow(true)
        setLink(lk)
    }
    return (
        <Container fluid>
        <Modal show={show} onHide={handleClose} style={{marginTop:"100px"}}>
        
            <Image style={{position: "absolute", width: "100%"}} src={`${url}/static/media/${link}`} />
        </Modal>
        <Row className="px-0">
            { photos.map((pic, idx)=>{
                return (
                    <Col sm={3} key={idx} className="photo_banner_sq ">
                        <Image className="banner_img" src={`${url}/static/media/${pic}`} onClick={()=>handleShow(pic)}/>
                    </Col>
                )
            })}
        </Row>    
        </Container>
    )
}
export default PhotoBanner