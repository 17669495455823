import { TelephoneFill } from "react-bootstrap-icons"
import { CircleFill } from "react-bootstrap-icons"
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import { tel } from "../urls"
import { isMobileDevice } from "../urls"
const PhoneButton = () => {
    const phone=`tel:${tel}`
    const renderTooltip = (props) => (
        <Tooltip id="button-tooltip" {...props}>
          {tel}
        </Tooltip>
      );
    return (
        <div className="button_list"> 
            { isMobileDevice ? 
            <a href={phone}>
                <CircleFill className="cr_icon" />
                <TelephoneFill className="bs_icon" /> 
            </a> 
            :
            <OverlayTrigger placement="bottom" overlay={renderTooltip}>
                <div>
                    <CircleFill className="cr_icon" />
                    <TelephoneFill className="bs_icon" />
                </div>
            </OverlayTrigger>
}
        </div>
    )
}
export default PhoneButton