// import { email, subject, location, tel, isMobileDevice, } from "../urls"
import EmailText from "../header/EmailText"
import LocationText from "../header/LocationText"
import PhoneText from "../header/PhoneText"
const CompanyInfo = () =>{
    
    return(
        <div style={{ color: "black"}} className="my-2">
            <EmailText />
            &nbsp;&nbsp;|  &nbsp;&nbsp;
            <PhoneText />
            &nbsp;&nbsp;| &nbsp;&nbsp; 
            <LocationText />
    
        </div>
    )
}
export default CompanyInfo