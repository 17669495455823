import MainCarousel from "./body/MainCarousel"
import PhotoBanner from "./body/PhotoBanner"
const Home = () => {
    return(
        <>
            <MainCarousel />
            <PhotoBanner />
        </>
    )
}
export default Home