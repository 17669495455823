import './App.css';

import Header from './features/header/Header';
import Footer from './features/footer/Footer';
// import UnderConstruction from './features/UnderConstruction';

import Home from './features/Home';
// import PhotoBanner from './features/body/PhotoBanner';
function App() {
 
  return (
    <div className="App">
     
        <Header />
        <Home />
        
        <Footer />
       
      
    </div>
  );
}

export default App;
