import { fbLink } from "../urls"
import { Facebook } from "react-bootstrap-icons"

const FacebookButton = () =>{
    
    return (
        <div className="button_list"> 
            <a href={fbLink}>
                <Facebook className="cr_icon fb_icon" />
            </a>
        </div>
           
    )
}
export default FacebookButton