import { Container, Navbar, Image } from "react-bootstrap"
import { url } from "../urls";
import FacebookButton from "./FacebookButton"
import PhoneButton from "./PhoneButton"
import EmailButton from "./EmailButton"
// import LocationButton from "./LocationButton";
import PhoneText from "./PhoneText";
import LocationText from "./LocationText";

const Header = () => {
    return (
        <Navbar bg="light" sticky="top" >
      <Container fluid>
        <a href="#index">
            <Image style={{height: "120px"}} src={`${url}/static/media/dmb_logo.png`} />

        </a>
        
        <Navbar.Collapse className="justify-content-end" style={{height: "100%"}}>
            <div >
                <div style={{display: "flex", position: "absolute", top: "10px", right: "10px"}}>
                    <FacebookButton />
                    <PhoneButton />
                    <EmailButton />
                    {/* <LocationButton /> */}
                </div>
            
            <div className="text1" >
                <div><PhoneText /></div>
                <div className="text2">&nbsp;|&nbsp;</div>
                <div><LocationText /></div>
                
                
            </div>
            </div>
        </Navbar.Collapse>
        </Container>
        </Navbar>
    )
}
export default Header