
const Engine =() => {
    return (
        <>
        <h2>Engine Parts, Rotating Assemblies and Complete Engines</h2>
        
        <p>
        DMB Performance Parts was established to cater to the performance SBC, and 
        BBC customers that are tired of having to wait months for parts or quality machine work.
        We work hard to source hard to find performance parts, including 
        Dart Blocks, Rotating assemblies, or even complete turn key engines.
        Later in 2023 we will introduce our own line of CNC ported cylinder 
        heads for BBC, SBC, and LS.
        </p>
  </>
    )
}
export default Engine