import { location } from "../urls";

const LocationText = () =>{
    
    return(
        <>
        {location}
        </>
    )
}
export default LocationText