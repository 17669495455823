import Carousel from 'react-bootstrap/Carousel';
import Engine from './Engine';
import CylinderHead from "./CylinderHead"
import MachineShop from "./MachineShop"
import Tools from "./Tools"
const MainCarousel =()=> {
  return (
    <Carousel>
      <Carousel.Item>
        <div className="black_div">
          <div className="slide_frame">
            <Engine />
          </div>
        </div>
        
      </Carousel.Item>
      <Carousel.Item>
        <div className="black_div">
          <div className="slide_frame">
            <MachineShop />
          </div>
        </div>
      </Carousel.Item>
      <Carousel.Item>
        <div className="black_div">
          <div className="slide_frame">
            <CylinderHead />
          </div>
        </div>
      </Carousel.Item>
      <Carousel.Item>
        <div className="black_div">
          <div className="slide_frame">
            <Tools />
          </div>
        </div>
      </Carousel.Item>
    </Carousel>
  );
}

export default MainCarousel;