export const url="https://dmbperformanceparts.com/"

export const fbLink = "https://www.facebook.com/profile.php?id=100090003866031"
export const tel = "833-722-3363"
export const email = "dmbperformanceparts@gmail.com"
export const subject="DMB Product Information"
export const location = "Bothell, WA"
export const photos = [
    "01D935A9-8BFF-426A-8403-FBD7704073EE.jpeg",
    "996C564C-B510-4811-BA14-23B4819FC311.jpeg",
    "9CFE678D-BE9C-4A62-9BC7-1620900D0E03.jpeg",
    "F2FDB975-C32A-457F-B82F-6F0E5B5D5F1D.jpeg",
    "FA57F0AA-FE68-4429-80D5-FA98A95CA6D7.jpeg",
    "2C03C8A4-04BE-4FA8-BAEE-87F886F38FE2.jpeg",
    "E25EBE05-7F1D-4AB6-AE78-911B1CDA60C9.jpeg"

]

export const isMobileDevice = /Mobi/i.test(window.navigator.userAgent)