import { CCircle } from "react-bootstrap-icons"
import CompanyInfo from "./CompanyInfo"
const Footer = () => {
    
    return (
        <div className="px-2">
            <CompanyInfo />

            <div style={{fontSize: "12px", color: "black"}}> 
                <CCircle />2023 DMBPerformanceParts.com 
            </div>
        </div>
    )
}
export default Footer